import React from "react"
import Foto from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cyc/img1.png"
import Foto2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cyc/img2.png"

//logo de empresas clientes
import CyC from "@components/pageCasosdeExito/image/logoscustomer/CyC.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import chatbot from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"


//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/cyc/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/cyc/img2.svg"

const text1 = (
  <span className="">
    Grupo C&C Computer es uno de los minoristas más grandes del Perú en comercialización de artículos de tecnología. Lleva más de 19 años posicionándose como la marca referente en precio y disponibilidad de productos de las mejores marcas para sus distribuidores.
    <br /> <br />
    Su operación de ecommerce ha aumentado sus ventas digitales en más del 40% con la ayuda de Beex, plataforma omnicanal de Beex que le permite potenciar su estrategia de remarketing y crosseling en WhatsApp y redes sociales.
  </span>
)

const text2 = (
  <span>
    C&C Computer, empresa líder en distribución y venta de productos y componentes tecnológicos, cuenta con diferentes tiendas a nivel nacional, pero es su operación de ecommerce la de mayor crecimiento en los últimos años, convirtiéndose en su principal operación de ventas en general.
    <br /> <br />
    La marca usa WhatsApp como su principal canal de comunicación, tanto para marketing como para ventas, siendo esencial para sus estrategias de remarketing y ventas cruzadas con sus clientes.
    <br /> <br />
    Oskar Martinez, Marketing Leader en C&C, ha acompañado en la implementación de Beex en su operación digital, obteniendo importantes mejoras en cuánto a los objetivos de ventas se refiere. Como menciona, la versión API de WhatsApp Business ha sido de lo más importante en su operación por el nivel de conversión que tiene.
    <br /> <br />
    Y no es para menos, para sus procesos de marketing y ventas, C&C se apoya en WhatsApp para mejorar sus estrategias con sus leads y, también, para sus clientes, a quiénes busca fidelizar y mejorar su ticket de compra con funcionalidades de remarketing y crosseling.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex nos ha otorgado oportunidades y beneficios para ecommerce que nos han generado un incremento en pedidos a nivel nacional. Sobre todo por su canal de WhatsApp, el cuál nos genera mayor conversión. Además, ahora somos capaces de responder a nuestros usuarios en &lt;1 minuto.”
  </p>
)

const text4 = ""

const text5 = (
  <span>
    Anteriormente, la operación de ventas de C&C gastaba mucho tiempo en su proceso de prospección al hacerlo manual. Incluso, no llegaban a atender a todos sus leads de digital por la cantidad que llegaban del tráfico orgánico y de pautas comerciales.
    <br /> <br />
    Esto cambió al implementar Beex y usar la versión de WhatsApp Business API, ya que una de las funcionalidades que más han estado aprovechando, es la de chatbots. Actualmente, cuenta con chatbots por WhatsApp que prospectan a sus leads y priorizan a aquellos que tienen mayor tendencia al cierre.
    <br /> <br />
    Además, como nos cuenta Oskar, también cuentan con chatbots para atención al cliente, el cuál los ayuda en su soporte post-venta. Esto último es muy importante debido al tipo de productos que venden, los cuáles, muchas veces, necesitan de este acompañamiento.
  </span>
)
const text6 = (
  <span>
    “Los chatbots en Beex nos ayudan a agilizar el saber que piensan o buscan nuestros clientes al momento de comunicarse con nosotros y, a su vez, ayuda a nuestros asesores a ser más eficientes en sus respuestas. Esto, en general, nos ha permitido aumentar nuestras ventas en +40%.”
  </span>
)

const text7 = (
  <span>
    Para los procesos de remarketing, WhatsApp también es un aliado importante. En la experiencia de C&C, hacer remarketing a través de campañas masivas por WhatsApp, tiene un nivel de conversión mucho mayor que al hacerlo por otros canales, como con correo electrónico.
    <br /> <br />
    Como mencionamos, anteriormente, su principal canal para comunicación masiva era el correo electrónico. Y, si bien tenían respuesta porque eran comunicaciones a clientes que esperaban un contacto, siempre tuvieron como objetivo mejorar sus ventas cruzadas por este tipo de acciones. Aquí es donde WhatsApp potenció el proceso.
    <br /> <br />
    Además, Oskar nos menciona que, si bien las campañas masivas han ayudado mucho al remarketing, también lo hacen en sus procesos de fidelización, ya que ahora se les hace sencillo enviar encuestas masivas post-venta a sus clientes.
  </span>
)
const text8 = (
  <span>
    “Nos apoyamos en las comunicaciones masivas por WhatsApp para mejorar nuestro ticket de compra. Además, por su facilidad de uso, en donde solo importamos contactos y enviamos plantillas, hemos mejorado nuestros procesos de venta y fidelización.”
  </span>
)

const text9 = (
  <span>
    Como es usual en los usuarios de Beex, las funcionalidades de nuestra plataforma omnicanal son muy apreciadas en el día a día. En este caso, las funcionalidades de gestión multiusuario y supervisión en tiempo real son de las más aprovechadas para C&C.
    <br /> <br />
    Al contar con diferentes tiendas a nivel nacional y ser una operación mayor de ecommerce, C&C ha sumado muchos usuarios en la plataforma para atender sus diferentes gestiones, como atención al cliente y ventas.
    <br /> <br />
    Actualmente, al contar con diferentes usuarios conectados, la función de supervisión en tiempo real de Beex es ideal para darle un seguimiento adecuado a su operación. Como menciona Oskar, es una funcionalidad que usan día a día para mejorar su control interno.
  </span>
)
const text10 = (
  <span>
    “A diferencia de antes, actualmente podemos monitorear en tiempo real a nuestros agentes sin que afecte el rendimiento de su gestión. Esto es vital para brindarles un soporte en tiempo real en caso necesitaran o requieran una supervisión directa.”
  </span>
)

const text11 = (
  <span>
    Estos son algunos de los procesos en los que Beex, a través de Beex, ayuda a Grupo C&C en su operación de atención al cliente y ventas.
  </span>
)


const data = {
  intro: {
    image: CyC,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: Foto,
    text4: text4,
    title5: "Chatbots en WhatsApp para ventas",
    text5: text5,
    text6: text6,
    title7: "Remarketing por WhatsApp masivos",
    text7: text7,
    text8: text8,
    fototwo: Foto2,
    title9: "Supervisión en tiempo real",
    text9: text9,
    text10: text10,
    text11: text11,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de ventas digitales. Beex ha logrado mejorar el contacto y comunicación con sus clientes, optimizando su proceso de prospección con sus leads.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Envío de campañas masivas de WhatsApp para su operación de marketing y ventas. Proceso simple y rápido con el uso de plantillas pre-aprobadas de WhatsApp, lo que les ha permitido optimizar su proceso de remarketing.",
    },
    {
      title: "Chatbot",
      img: chatbot,
      info:
        "Implementación de chatbots para su operación de ventas. Diseño y creción por rangos horarios para brindar respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Gestión multiagente",
      img: gestion,
      info:
        "Creación y administración de múltiples usuarios para gestionar bajo una sola cuenta de WhatsApp. Gestión de colas de atención asignadas a chatbots con enrutamiento inteligente de casos.",
    },
  ],
}

export default data
